<template>
  <div>
    <search-filter
        :items="orders"
        :isActionColumnEnabled="true"
        itemView="list"
        :sortOptions="sortOptions"
        :columns="columns"
        :show-past-years-filter="true"
        @columnClick="$event.number == null?selectOrder($event):$router.push({ name: 'Order view', params: { orderId: $event.id }})"
        @addItem="addOrder"
    >
      <!--  =========================== -->
      <!--  ===    Results infos    === -->
      <!--  =========================== -->
      <template #resultInfos="{items, isLoading}">
        <icon
            v-if="isLoading"
            icon="spinner"
            :spin="true"
            class="text-warning"
        />

        ({{ items.length }})
        <span>{{ currency(getPreTaxAmounts(items)) }} HT / {{ currency(getTotalAmounts(items)) }} TTC</span>
      </template>

      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <card-order
            :order="item"
            @click="item.number == null?selectOrder(item):$router.push({ name: 'Order view', params: { orderId: item.id }})"
            @deleteOrder="deleteOrderAlert(item)"
        />
      </template>

      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Column status-->
      <template #listView_head_status="{item}">
        <icon icon="chart-line"/>
      </template>
      <template #listView_cell_status="{item}">
        <b-avatar
            :id="`order-row-${item.id}`"
            size="32"
            :variant="`light-${getOrderIconStatus(item).variant}`"
        >
          <icon
              :icon="getOrderIconStatus(item).icon"
          />
        </b-avatar>
        <b-tooltip
            :target="`order-row-${item.id}`"
            placement="left"
        >
          <p class="mb-0">
            {{ capitalize($t(getOrderStatus(item))) }}
          </p>
        </b-tooltip>
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          View-->
          <button-view
              @click.native.stop="$router.push({ name: 'Order view', params: { orderId: item.id }})"
              v-b-tooltip.hover.left="capitalize($t('View'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Download-->
          <button-download
              @click.native.stop="item.incomingInvoice!= null?downloadOrderIncomingInvoice(item):downloadOrder(item)"
              v-b-tooltip.hover.left="capitalize($t('Download'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />


          <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              right
          >
            <template #button-content>
              <button-dropdown
                  :withIcon="true"
                  :withText="false"
                  :withBorder="false"
                  size="sm"
              />
            </template>

            <!--          Validate-->
            <b-dropdown-item
                v-if="item.number == null && $can('manage', 'all')"
                @click.stop="validateOrderLocal(item)">
              <icon
                  icon="check"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('validate') }}</span>
            </b-dropdown-item>

            <!--          Edit-->
            <b-dropdown-item
                v-if="item.number == null && $can('manage', 'all')"
                @click.stop="$router.push({ name: 'Order edit', params: { orderId: item.id }})">
              <icon
                  icon="edit"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('Edit') }}</span>
            </b-dropdown-item>

            <!--          Duplicate -->
            <b-dropdown-item
                @click.stop="duplicateOrder(item)"
            >
              <icon
                  icon="copy"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('duplicate')) }}</span>
            </b-dropdown-item>

            <!--          Accept -->
            <b-dropdown-item
                v-if="item.number != null && !item._filters[2].value"
                @click.stop="acceptOrderLocal(item)"
            >
              <icon
                  icon="file-signature"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('orderAccepted') }}</span>
            </b-dropdown-item>

            <!--          Billing -->
            <b-dropdown-item
                v-if="item.number != null && item._filters[2].value"
                @click.stop="billOrder(item)"
            >
              <icon
                  icon="file-invoice-dollar"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('SaveTheBill') }}</span>
            </b-dropdown-item>

            <!--          Archivate-->
            <b-dropdown-item
                v-if="item.isArchived == false"
                @click.stop="archivateOrder(item)"
            >
              <icon
                  icon="box"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('Archivate')) }}</span>
            </b-dropdown-item>

            <!--          Activate-->
            <b-dropdown-item
                v-if="item.isArchived == true"
                @click.stop="unarchivateOrder(item, false)"
            >
              <icon
                  icon="box-open"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('Unarchive')) }}</span>
            </b-dropdown-item>

            <!--          Delete -->
            <b-dropdown-item
                v-if="item.number ==null"
                @click.stop="deleteOrderAlert(item)"
            >
              <icon
                  icon="times"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('delete')) }}</span>
            </b-dropdown-item>

          </b-dropdown>

        </div>
      </template>

    </search-filter>

    <!-- modal order-->
    <modal-order
        :order.sync="currentOrder"
        :title="orderModalTitle"
        :isOpen.sync="orderModalShow"
        @submitValidatedOrder="submitValidatedOrderLocal"
    />

    <!-- modal order validation-->
    <modal-order-validation
        :title="$t('OrderValidation')"
        :offer.sync="currentOrder"
        :isOpen.sync="isOrderAcceptanceModalOpen"
        @submitValidatedOfferValidationForm="submitValidatedOrderLocal"
    />

    <!-- modal upload incoming invoice -->
    <modal-upload-incoming-invoice
        :singleFile.sync="newIncomingInvoiceFile"
        :documentType="'incomingInvoice'"
        :title="capitalize($tc('invoice'))"
        :isOpen.sync="incomingInvoiceByUploadModalShow"
    />

    <!-- modal incoming invoice-->
    <modal-incoming-invoice
        :incomingInvoice.sync="newIncomingInvoice!=null?newIncomingInvoice:currentOrder.incomingInvoice"
        :title="incomingInvoiceModalTitle"
        :isOpen.sync="incomingInvoiceModalShow"
        @submitValidatedIncomingInvoice="submitValidatedIncomingInvoiceLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinOrders } from '../../../views/purchases/orders/mixinOrders'
import { useOrders } from '@/views/purchases/orders/useOrders'

import SearchFilter from '../../searchFilterV2/SearchFilter'
import CardOrder from '@/components/card/Order'
import ModalUploadIncomingInvoice from '@/components/prompt/Upload'
import ModalIncomingInvoice from '@/components/prompt/IncomingInvoice'
import i18n from '@/libs/i18n'
import ButtonView from '@/components/button/View'
import ButtonDownload from '@/components/button/Download'
import ButtonDropdown from '@/components/button/Dropdown'
import { capitalize } from '../../../utils/filter'
import { getRoute } from '@/utils/utils'

export default {
  components: {
    SearchFilter,
    CardOrder,
    ModalUploadIncomingInvoice,
    ModalIncomingInvoice,
    ButtonView,
    ButtonDownload,
    ButtonDropdown
  },
  mixins: [mixinOrders],
  props: {
    orders: {
      type: Array,
      default: () => []
    },
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sortOptions = ref([
      {
        text: capitalize(i18n.tc('draft')),
        key: '_filter_isDraftStatus',
      },
      // {
      //   text: capitalize(i18n.tc('internalValidated')),
      //   key: '_filter_isInternalValidated',
      // },
      {
        text: capitalize(i18n.t('accepted')),
        key: '_filter_isAccepted',
      },
      {
        text: capitalize(i18n.t('invoiced')),
        key: '_filter_isInvoicedStatus',
      },
      {
        text: capitalize(i18n.t('archived')),
        key: '_filter_isArchived',
      },
      {
        text: capitalize(i18n.t('active')),
        key: '_filter_isActive',
        selected: getRoute().name == 'Workflow view'?false:true
      },
    ])

    const columns = ref([
      {
        display: '#',
        key: 'number',
        sortable: true,
      },
      {
        display: capitalize(i18n.t('status')),
        key: 'status',
      },
      {
        display: capitalize(i18n.t('supplier')),
        key: 'supplierCompany',
        sortable: true
      },
      {
        display: capitalize(i18n.t('preTaxAmount')),
        key: 'preTaxAmount',
        sortable: true
      },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    const {
      getOrderStatus,
      getOrderIconStatus,
    } = useOrders()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      sortOptions,
      columns,

      // Computed

      // Methods
      getOrderStatus,
      getOrderIconStatus,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () { },
  created () { }
}
</script>

<style lang="scss">

</style>